import { preventDefault } from "../../../resources";

export default function FinalDecision() {
    return <div class="card-body" style={{ marginLeft: `30px` }}>
        <h5 class="card-title">Final Decision</h5>
        <form onSubmit={preventDefault}>
            <div class="col-sm-10">
                You will soon reveive an email from us containing our final decision. Best of Luck!
                <br />
                <br />
                {/* <button type="submit" class="btn btn-primary">Check mail</button> */}
            </div>
        </form>
    </div>
}