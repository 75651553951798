import { useEffect, useState } from "react";
import { getUserDetails, postRequestTo, preventDefault, showLoadingScreen } from "../../../resources";
import $ from "jquery";

/* global Swal */

export default function PersonalDetails() {

    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        qualifications: "",
        dateOfBirth: "",
    });


    function submit() {
        if ((!$('#checkbox1').is(":checked"))) {
            Swal.fire(
                'Your acknowledgemnt is required',
                `Check the box at the end of the form to show your acknowledgemnt`,
                'warning'
            )
            return;
        }

        showLoadingScreen();
        postRequestTo("agree", getUserDetails(), (res) => {
            Swal.close();
            if (res.status === "success") {
                window.location.reload();
            } else {
                Swal.fire(
                    'Something went wrong',
                    `An unknown error has occured!`,
                    'warning'
                )
            }
        });

    }

    useEffect(() => {
        showLoadingScreen();
        postRequestTo("get-details", getUserDetails(), (res) => {
            Swal.close();
            if (res.status === "success") {
                setState({ ...state, ...res.data });
            } else {
                Swal.fire(
                    'Something went wrong',
                    `An unknown error has occured!`,
                    'warning'
                )
            }
        });
    }, [])

    return <div class="card-body" style={{ marginLeft: `30px` }}>

        <h5 class="card-title">Personal Details</h5>
        <form onSubmit={preventDefault}>
            <div className="col-lg-6">
                <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">First Name</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" value={state.firstName.toUpperCase()} disabled />
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Last Name</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" value={state.lastName.toUpperCase()} disabled />
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Phone Number</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" value={state.phoneNumber} disabled />
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Email </label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" value={state.email} disabled />
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Qualifications</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" value={state.qualifications.toUpperCase()} disabled />
                    </div>
                </div>
            </div>
            <div className="col-lg-6">
                <div class="row mb-3">
                    <label for="inputText" class="col-sm-2 col-form-label">Date of Birth</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" value={state.dateOfBirth.toUpperCase()} disabled />
                    </div>
                </div>
            </div>
            <br />
            <div class="col-sm-10">

                {/* <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="checkbox1" />
                    <label class="form-check-label" for="checkbox1">
                        I acknowledge that the details provided are correct to the best of my knowledge.
                    </label>
                </div> */}

                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="checkbox1" />
                    <label class="form-check-label" for="checkbox1">
                        I agree to share the above data for assessment and qulity and training purposes with the Phoenix AI project as well as consultants from Microsoft.
                    </label>
                </div>
                <br />
                <button type="submit" class="btn btn-primary" onClick={submit}>Submit</button>
            </div>
        </form>
    </div>
}