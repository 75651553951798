import { Link, useNavigate } from "react-router-dom";

export default function Navigation(props) {

  const navigateTo = useNavigate();

  function logout() {
    localStorage.clear()
    navigateTo("/home");
  }

  return <header id="header" className="header fixed-top">
    <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
      <Link className="logo d-flex align-items-center">
        <img src="/assets/img/icon/icon.png" alt="Phoenix AI Logo" />
        <span>Phoenix AI</span>
      </Link>

      <nav id="navbar" className="navbar">
        <ul>
          {(props.activePage === "home") ? "" : <li>
            <a href="/logout" className="getstarted scrollto" onClick={logout}> Logout </a>
          </li>}
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>
    </div>
  </header>
}