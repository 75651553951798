import { getUserDetails, postRequestTo, preventDefault, showLoadingScreen } from "../../../resources";
import { useEffect } from "react";
import $ from "jquery";

/* global Swal */

const personalityStatements = [
    "I feel overwhelmed in stressful situations.",
    "I bounce back quickly after setbacks.",
    "I often feel anxious about future events.",
    "I find it difficult to control my emotions.",
    "I enjoy experiencing a wide range of emotions.",
    "I feel comfortable speaking in front of large groups.",
    "I prefer spending time alone rather than with others.",
    "I enjoy meeting new people.",
    "I find it easy to strike up a conversation with strangers.",
    "I often feel drained after social interactions.",
    "I am highly motivated to achieve my goals.",
    "I find it easy to stay organized and on task.",
    "I often procrastinate when faced with difficult tasks.",
    "I am comfortable taking risks to achieve success.",
    "I work best under pressure.",
    "I enjoy trying new activities and hobbies.",
    "I prefer to stick to familiar routines.",
    "I enjoy exploring abstract ideas and concepts.",
    "I often come up with creative solutions to problems.",
    "I value art and beauty in everyday life.",
    "I try to avoid arguments and confrontations.",
    "I find it difficult to forgive others who have wronged me.",
    "I am quick to admit when I am wrong.",
    "I enjoy working collaboratively with others.",
    "I tend to stand my ground in disagreements.",
    "I am confident in my abilities.",
    "I often compare myself to others.",
    "I feel comfortable being myself around others.",
    "I frequently doubt my decisions.",
    "I take pride in my accomplishments.",
    "I make decisions quickly and confidently.",
    "I prefer to gather all information before making a decision.",
    "I often second-guess my choices.",
    "I enjoy solving puzzles and complex problems.",
    "I find it challenging to prioritize tasks.",
    "I believe that honesty is the most important virtue.",
    "I often reflect on the meaning and purpose of life.",
    "I am comfortable with change and uncertainty.",
    "I prefer to follow traditions and established norms.",
    "I value independence and self-reliance.",
    "I exercise regularly to maintain a healthy lifestyle.",
    "I often stay up late and have irregular sleep patterns.",
    "I find it easy to stick to a daily routine.",
    "I enjoy spending time in nature.",
    "I frequently use technology or social media in my free time.",
    "I remain calm in high-pressure situations.",
    "I seek support from others when I’m feeling stressed.",
    "I often feel overwhelmed by small tasks.",
    "I use hobbies or distractions to cope with stress.",
    "I tend to dwell on negative experiences."
];

export default function PersonalityAssessment() {

    useEffect(() => {

    }, []);

    function submit() {
        for (var i = 0; i < personalityStatements.length; i++) {
            if ($(`input[name=statement${i}]:checked`, '#myForm').val() === undefined) {
                Swal.fire(
                    'Some questions are left',
                    `Please answer the remaining questions`,
                    'warning'
                );
                return;
            }
        }

        let responseJSON = {
            answers: []
        }

        for (var i = 0; i < personalityStatements.length; i++) {
            responseJSON.answers.push({
                "question": personalityStatements[i],
                "answer": $(`input[name=statement${i}]:checked`, '#myForm').val(),
            })
        }

        showLoadingScreen();
        postRequestTo("personality-step-complete", { ...getUserDetails(), responseJSON: responseJSON }, (res) => {
            Swal.close();
            console.log(res)
            if (res.status === "success") {
                window.location.reload();
            } else {
                Swal.fire(
                    'Something went wrong',
                    `An unknown error has occured!`,
                    'warning'
                )
            }
        });

    }


    return <div class="card-body" style={{ marginLeft: `30px` }}>
        <form onSubmit={preventDefault} id="myForm">
            <h5 class="card-title">Personality Assessment</h5>
            For all the statements given below select whether you &nbsp;
            <i>
                (Strongly Disagree /
                Disagree /
                Neutral /
                Agree /
                Strongly Agree) &nbsp;
            </i>
            with that statement. Take as much time as you need.
            <br />
            <br />
            {
                personalityStatements.map((statement, index) => {
                    return <div>
                        <br />
                        {/* <div>{index + 1}. </div> */}
                        <div className="statement-statement"> {statement}</div>
                        <div className="statement-options">
                            <div className="statement-options-group">
                                <input class="form-check-input" type="radio" name={`statement${index}`} id={`statement${index}-SD`} value="SD"></input>
                                <label class="form-check-label" htmlFor={`statement${index}-SD`}>
                                    Strongly Disagree
                                </label>
                            </div>

                            <div className="statement-options-group">
                                <input class="form-check-input" type="radio" name={`statement${index}`} id={`statement${index}-D`} value="D"></input>
                                <label class="form-check-label" htmlFor={`statement${index}-D`}>
                                    Disagree
                                </label>
                            </div>

                            <div className="statement-options-group">
                                <input class="form-check-input" type="radio" name={`statement${index}`} id={`statement${index}-N`} value="N"></input>
                                <label class="form-check-label" htmlFor={`statement${index}-N`}>
                                    Neutral
                                </label>
                            </div>

                            <div className="statement-options-group">
                                <input class="form-check-input" type="radio" name={`statement${index}`} id={`statement${index}-A`} value="A"></input>
                                <label class="form-check-label" htmlFor={`statement${index}-A`}>
                                    Agree
                                </label>
                            </div>

                            <div className="statement-options-group">
                                <input class="form-check-input" type="radio" name={`statement${index}`} id={`statement${index}-SA`} value="SA"></input>
                                <label class="form-check-label" htmlFor={`statement${index}-SA`}>
                                    Strongly Agree
                                </label>
                            </div>
                        </div>
                    </div>

                })
            }
        </form>
        <button type="submit" class="btn btn-primary" onClick={submit}>Submit</button>
    </div>
}