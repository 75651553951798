import { launchLink, preventDefault } from "../../../resources";

export default function PersonalInterview() {
    return <div class="card-body" style={{ marginLeft: `30px` }}>
        <h5 class="card-title">Personal Interview</h5>
        <form onSubmit={preventDefault}>
            <div class="col-sm-10">
                We are evaluating your responses. If you are selected for the interview round you will soon reveive an email from us containing all the information about the scheduled interview. The email will contain the following information:
                <br />
                <br />
                <ul>
                    <li>Date of the interview</li>
                    <li>Time of the interview</li>
                    <li>Link to join the interview</li>
                    <li>Provision to re-schedule</li>
                </ul>
                <br />
                {/* <a href="https://mail.google.com/mail/" target="_top">
                    <button type="submit" class="btn btn-primary" >Check mail</button>
                </a> */}
            </div>
        </form>
    </div>
}