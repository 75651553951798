import $ from "jquery";

/* global Swal */

const aosDelay = 10;

const serverSideUrl = "https://phoenix-ai-hiring-portal-backend.vercel.app/";
const mediaUrl = "";
const requestUrl = serverSideUrl + "request";
const widgetsUrl = requestUrl + "widgets/";

const hrefValue = "";

const preventDefault = (e) => { e.preventDefault() }

const postRequestTo = function (url, data, success) {
    // $.ajax({
    //     url: (custom) ? encodeURI(url + "&reqID=" + unique(8)) : encodeURI(requestUrl + url),
    //     type: (custom) ? "get" : "post",
    //     data: data,
    //     success: success,
    //     error: (e) => {
    //         if (!navigator.onLine) {
    //             Swal.fire(
    //                 'Offline',
    //                 `The device has no active internet connection`,
    //                 'warning'
    //             )
    //         } else {
    //             Swal.fire(
    //                 'Trouble reaching our servers',
    //                 `Please try again after sometime`,
    //                 'warning'
    //             )
    //         }
    //         error(e);
    //     },
    //     processData: false,
    //     "Content-Type": "application/json"
    // })

    var settings = {
        "url": `${requestUrl}/${url}`,
        "method": "POST",
        "timeout": 0,
        "headers": {
            "Content-Type": "application/json"
        },
        "data": JSON.stringify(data),
    };

    $.ajax(settings).done(success);
}

const csvJSON = function (csv) {

    var lines = csv.replace(/\r/g, "").split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {

        var obj = [];
        var currentline = lines[i].split(",");

        for (var j = 0; j < headers.length; j++) {
            obj.push(currentline[j]);
        }

        result.push(obj);

    }

    return JSON.stringify(result); //JSON
}

const unique = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

const launchLink = (d) => {
    window.open(d, `_blank`)
}

const showLoadingScreen = (msg = "Loading") => {
    Swal.fire({
        title: msg,
        html: `
        <div class="msSpin">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only"></span>
            </div>
        </div>
        `,
        allowEscapeKey: false,
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
    });
}


const checkPermissionError = (r) => {
    if (r.includes("PERMISSION_DENIED")) {
        Swal.fire(
            'Permission denied',
            `You do not have the permission to perform this action`,
            'warning'
        );
    }
}

const getUserDetails = () => {
    return (localStorage.getItem("candidateID") === null) ? null : {
        candidateID: localStorage.getItem("candidateID"),
        password: localStorage.getItem("password"),
    }
}

const setUserDetails = (candidateID, password) => {
    localStorage.setItem("candidateID", candidateID);
    localStorage.setItem("password", password);

}

export { aosDelay, mediaUrl, requestUrl, widgetsUrl, hrefValue, getUserDetails, setUserDetails, postRequestTo, csvJSON, preventDefault, unique, launchLink, showLoadingScreen, checkPermissionError }