import WebPage from "../../components/webpage/WebPage";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PersonalDetails from "./sections/PersonalDetails";
import PersonalityAssessment from "./sections/PersonalityAssessment";
import PersonalInterview from "./sections/PersonalInterview";
import GroundWorkAssessment from "./sections/GroundWorkAssessment";
import FinalDecision from "./sections/FinalDecision";
import CommunicationAssessment from "./sections/CommunicationAssessment";
import { getUserDetails, postRequestTo, showLoadingScreen } from "../../resources";


/* global Swal */

export default function ProtalPage() {

    const [state, setState] = useState({
        stepNumber: 6,
    });

    const navigateTo = useNavigate();

    useEffect(() => {
        if (getUserDetails() === null) {
            navigateTo("/home");
            return;
        }

        showLoadingScreen();
        postRequestTo("get-details", getUserDetails(), (res) => {
            Swal.close();
            if (res.status === "success") {
                setState({ ...state, stepNumber: res.data.step });
            } else {
                Swal.fire(
                    'Something went wrong',
                    `An unknown error has occured!`,
                    'warning'
                )
            }
        });
    }, []);



    return <WebPage activePage="portal" >
        <main>
            <section class="section">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="card application">
                            <div class="card-body application-progress-wrap">
                                <div class="progress application-progress" >
                                    <div class="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{ width: `${((state.stepNumber - 1) / 5 * 100)}%` }} ></div>
                                </div>
                                <div className="application-parts">
                                    <div className="application-part">
                                        <button type="button" class="btn btn-primary btn-sm">1</button>
                                        <span>Personal details</span>
                                    </div>
                                    <div className="application-part">
                                        <button type="button" class="btn btn-primary btn-sm">2</button>
                                        <span>Personality assessment</span>
                                    </div>
                                    <div className="application-part">
                                        <button type="button" class="btn btn-primary btn-sm">3</button>
                                        <span>Communication assessment</span>
                                    </div>
                                    <div className="application-part">
                                        <button type="button" class="btn btn-primary btn-sm">4</button>
                                        <span>Personal interview</span>
                                    </div>
                                    <div className="application-part">
                                        <button type="button" class="btn btn-primary btn-sm">5</button>
                                        <span>Task assessment</span>
                                    </div>
                                    <div className="application-part">
                                        <button type="button" class="btn btn-primary btn-sm">6</button>
                                        <span>Final decision</span>
                                    </div>
                                </div>
                            </div>
                            <br />
                            {
                                (state.stepNumber === 1) ? <PersonalDetails /> : ""
                            }
                            {
                                (state.stepNumber === 2) ? <PersonalityAssessment /> : ""
                            }
                            {
                                (state.stepNumber === 3) ? <CommunicationAssessment /> : ""
                            }
                            {
                                (state.stepNumber === 4) ? <PersonalInterview /> : ""
                            }
                            {
                                (state.stepNumber === 5) ? <GroundWorkAssessment /> : ""
                            }
                            {
                                (state.stepNumber === 6) ? <FinalDecision /> : ""
                            }
                            <br />
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </WebPage >
}