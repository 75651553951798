import WebPage from "../../components/webpage/WebPage";
import { getUserDetails, postRequestTo, preventDefault, setUserDetails, showLoadingScreen } from "../../resources";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import $ from "jquery";
import "./Home.css";

/* global Swal */

export default function HomePage() {
    const navigateTo = useNavigate();

    useEffect(() => {
        if (getUserDetails() !== null) {
            navigateTo("/portal");
            return;
        }
    }, []);

    function auth() {

        const candidateID = $("#yourUsername").val();
        const password = $("#yourPassword").val();

        if (candidateID.length === 0 || password.length === 0) return;

        const data = {
            candidateID: candidateID,
            password: password,
        }

        showLoadingScreen();
        postRequestTo("auth", data, (res) => {
            Swal.close();
            if (res.status === "success") {
                setUserDetails(candidateID, password);
                navigateTo("/portal");
            } else {
                Swal.fire(
                    'Wrong Credentials',
                    `Wrong username or password, try again!`,
                    'warning'
                )
            }
        });

    }

    return <WebPage activePage="home" >
        <main>

            <div class="container">

                <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                <div class="d-flex justify-content-center py-4">
                                    <img src="assets/img/logo.png" alt="" />
                                    <span class="d-none d-lg-block login-title">Phoenix AI Hiring Portal</span>
                                </div>

                                <div class="card mb-3">

                                    <div class="card-body">

                                        <div class="pt-4 pb-2">
                                            <h5 class="card-title text-center pb-0 fs-4">Candidate Login</h5>
                                            <p class="text-center small">Enter your CandidateID & Password to login</p>
                                        </div>

                                        <form class="row g-3 needs-validation" novalidate={true} onSubmit={preventDefault}>

                                            <div class="col-12">
                                                <label for="yourUsername" class="form-label">CandidateID</label>
                                                <input formNoValidate type="text" name="username" class="form-control" id="yourUsername" autoComplete="off" required />
                                                <div class="invalid-feedback">Please enter your username.</div>
                                            </div>

                                            <div class="col-12">
                                                <label for="yourPassword" class="form-label">Password</label>
                                                <input formNoValidate type="password" name="password" class="form-control" id="yourPassword" autoComplete="off" required />
                                                <div class="invalid-feedback">Please enter your password!</div>
                                            </div>
                                            <div class="col-12">
                                                <button class="btn btn-primary w-100" type="submit" onClick={auth}>Login</button>
                                            </div>
                                            <div class="col-12">
                                                <p class="small mb-0">Having trouble loggin in? <a href="tel:+917737667791">Contact Us</a></p>
                                            </div>
                                        </form>

                                    </div>
                                </div>

                                <div class="credits">


                                </div>

                            </div>
                        </div>
                    </div>

                </section>

            </div>
        </main>
    </WebPage>
}