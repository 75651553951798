import ProtalPage from "./pages/portal/Portal";
import HomePage from "./pages/home/Home";

/* react-router-dom */
import { Navigate } from "react-router-dom";

const routes = [
    {
        path: "/home",
        page: <Navigate to="/" />
    },
    {
        path: "*",
        page: <Navigate to="/" />
    },
    {
        path: "/",
        page: <HomePage />
    },
    {
        path: "/portal",
        page: <ProtalPage />
    },
]

export default routes;