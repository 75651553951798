import { getUserDetails, postRequestTo, preventDefault, showLoadingScreen } from "../../../resources";
import { useEffect } from "react";
import $ from "jquery";

/* global Swal */
const communicationSkillQuestions = [
    "Explain a complex concept or process (e.g., how the internet works or how a car engine functions) in simple terms that a 10-year-old could understand.",
    "Imagine you are writing to a client to apologize for a delay in delivering a product. Draft a polite and professional email explaining the situation.",
    "Your team has misunderstood the instructions for an important project, leading to errors. Describe how you would communicate the correct instructions clearly to avoid future mistakes.",
    "You are reviewing a colleague's work and notice some mistakes. Write how you would provide constructive feedback to them in a respectful and helpful way.",
    "Write a brief message persuading your manager to approve a proposal for implementing a new software tool that could improve team efficiency."
];

export default function CommunicationAssessment() {

    function submit() {
        for (var i = 0; i < communicationSkillQuestions.length; i++) {
            if ($(`#answer${i}`).val().length < 50) {
                Swal.fire(
                    'Some questions are left',
                    `Please answer the remaining questions`,
                    'warning'
                );
                return;
            }
        }

        let responseJSON = {
            answers: []
        }

        for (var i = 0; i < communicationSkillQuestions.length; i++) {
            responseJSON.answers.push({
                "question": communicationSkillQuestions[i],
                "answer": $(`#answer${i}`).val(),
            })
        }

        showLoadingScreen();
        postRequestTo("communication-step-complete", { ...getUserDetails(), responseJSON: responseJSON }, (res) => {
            Swal.close();
            console.log(res)
            if (res.status === "success") {
                window.location.reload();
            } else {
                Swal.fire(
                    'Something went wrong',
                    `An unknown error has occured!`,
                    'warning'
                )
            }
        });

    }

    return <div class="card-body" style={{ marginLeft: `30px` }}>
        <h5 class="card-title">Communication Skills Assessment</h5>
        <form onSubmit={preventDefault}>
            <div class="col-sm-10">
                Answer the questions below (in at least 50 characters but not more than 2000 characters):
                <br />
                <br />
                {
                    communicationSkillQuestions.map((question, index) => {
                        return <div>
                            <div class="row mb-3">
                                <label for="inputPassword" class="col-sm-12 col-form-label"><i>{question}</i></label>
                                <div class="col-sm-12">
                                    <textarea id={`answer${index}`} class="form-control" style={{ height: `150px` }} maxLength={2000}></textarea>
                                </div>
                            </div>
                        </div>
                    })
                }
                <br />
                <br />
                <button type="submit" class="btn btn-primary" onClick={submit}>Submit</button>
            </div>
        </form>
    </div>
}