import { useEffect, useState } from "react";
import { getUserDetails, postRequestTo, preventDefault, showLoadingScreen } from "../../../resources";
import $ from "jquery";

/* global Swal */

export default function GroundWorkAssessment() {

    const [state, setState] = useState({
        prospectsApproached: 0,
        usersOnBoarded: 0,
        max: 0,
    });

    function loadMax() {
        postRequestTo("get-max", getUserDetails(), (res) => {
            Swal.close();
            if (res.status === "success") {
                console.log(res)
                state.max = res.data;
                setState({ ...state, max: state.max });
            } else {
                Swal.fire(
                    'Something went wrong',
                    `An unknown error has occured!`,
                    'warning'
                )
            }
        });
    }

    useEffect(() => {
        showLoadingScreen();
        postRequestTo("get-details", getUserDetails(), (res) => {
            Swal.close();
            if (res.status === "success") {
                state.prospectsApproached = res.data.prospectsApproached;
                state.usersOnBoarded = res.data.usersOnBoarded;
                setState({ ...state, prospectsApproached: res.data.prospectsApproached, usersOnBoarded: res.data.usersOnBoarded });
                loadMax()
            } else {
                Swal.fire(
                    'Something went wrong',
                    `An unknown error has occured!`,
                    'warning'
                )
            }
        });
    }, []);

    return <div class="card-body dashboard" style={{ marginLeft: `30px` }}>
        <form onSubmit={preventDefault}>
            <h5 class="card-title">Ground Work Assessment</h5>
            Here is an overview of the groundwork done by you &nbsp;
            <br />
            <br />
            <div class="col-lg-12">
                <div class="row">

                    <div class="col-xxl-4 col-md-6">
                        <div class="card info-card sales-card">
                            <div class="card-body">
                                <h5 class="card-title">Leads Contacted</h5>
                                <div class="d-flex align-items-center">
                                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center" style={{ color: '#FFC107', background: 'rgb(255 237 183)' }}>
                                        <i class="bi bi-person"></i>
                                    </div>
                                    <div class="ps-3">
                                        <h6>{state.prospectsApproached}</h6>
                                        {/* <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xxl-4 col-md-6">
                        <div class="card info-card sales-card">
                            <div class="card-body">
                                <h5 class="card-title">Users Onboarded</h5>
                                <div class="d-flex align-items-center">
                                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center" style={{ color: '#2eca6a', background: '#e0f8e9' }}>
                                        <i class="bi bi-check-lg"></i>
                                    </div>
                                    <div class="ps-3">
                                        <h6>{state.usersOnBoarded}</h6>
                                        {/* <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xxl-4 col-md-6">
                        <div class="card info-card sales-card">
                            <div class="card-body">
                                <h5 class="card-title">Maximum Users Onboarded</h5>
                                <div class="d-flex align-items-center">
                                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center" style={{ color: '#2196F3', background: 'rgb(204 233 255)' }}>
                                        <i class="bi bi-graph-up-arrow"></i>
                                    </div>
                                    <div class="ps-3">
                                        <h6>{state.max}</h6>
                                        {/* <span class="text-success small pt-1 fw-bold">12%</span> <span class="text-muted small pt-2 ps-1">increase</span> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
}